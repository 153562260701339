import React from "react"
import { graphql } from "gatsby"
import SEO from './seo'
import './post.scss';

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <div className="post-container">
      <SEO title={frontmatter.title} />
      <div className="post">
      <div className="content">
        <h1>{frontmatter.title}</h1>
        <p className="author">By <a href="/">Tony Garvan</a></p>
        <div dangerouslySetInnerHTML={{ __html: html }}/>
      </div>
      <div className="published">{frontmatter.published}</div>
    </div>
    <footer>
      © {new Date().getFullYear()}, Anthony Garvan
    </footer>
  </div>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        published
      }
    }
  }
`
